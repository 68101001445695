import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDxYhL0-8WJOQcKWdNFlzQ4lRQpVlUP7Xc",
  authDomain: "nx-miner.firebaseapp.com",
  projectId: "nx-miner",
  storageBucket: "nx-miner.appspot.com",
  messagingSenderId: "399604298030",
  appId: "1:399604298030:web:d02f7aca83b7d2041a0abf"
};

initializeApp(firebaseConfig);
const db = getFirestore()
export default db