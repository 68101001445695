import { dateUtils } from "./dateUtils"
import {  balanceObject, machineObject, referralUtils} from "./referralUtils"

export class balanceUtils{
    static async getAllBalance(referralCode :string,balanceObject :balanceObject, machineObject :machineObject[], currentTime :number):Promise<balanceObject>{
        const commission = await referralUtils.getCommissionForEachCoin(referralCode,currentTime)
        return{
            usdt: balanceObject.usdt, //done 
            //firebase value + miner income + commission
            btc: await this.getBtcBalance(machineObject,balanceObject.btc,currentTime) +   commission.btc,
            eth: await this.getEthBalance(machineObject,balanceObject.eth,currentTime) +   commission.eth,
            doge: await this.getDogeBalance(machineObject,balanceObject.doge,currentTime) +   commission.doge,
            zcash: await this.getZecBalance(machineObject,balanceObject.zcash,currentTime) +   commission.zec,
        }
    }

   
    
    static async getBtcBalance(machineObject: machineObject[], btc :number,currentTime : number):Promise<number>{
        const filteredMachines = machineObject.filter(mac=> mac.model>=1 && mac.model<=6 )
        return await this.calculateProfitByCoin(filteredMachines, currentTime) + btc
    }

    static async getEthBalance(machineObject :machineObject[], eth :number,currentTime : number):Promise<number>{
        const filteredMachines = machineObject.filter(mac=> mac.model>=7 && mac.model<=12)
        return await this.calculateProfitByCoin(filteredMachines, currentTime) + eth
    }

    static async getDogeBalance(machineObject :machineObject[], doge: number,currentTime : number):Promise<number>{
        const filteredMachines = machineObject.filter(mac=> mac.model>=13 && mac.model<=18)
        return await this.calculateProfitByCoin(filteredMachines, currentTime) + doge
    }

    static async getZecBalance(machineObject :machineObject[], zec :number,currentTime : number):Promise<number>{
        const filteredMachines = machineObject.filter(mac=> mac.model>=19 && mac.model<=24)
        return await this.calculateProfitByCoin(filteredMachines, currentTime) + zec
    }

    static async calculateProfitByCoin(machine:machineObject[],currentTime : number):Promise<number>{
        let total = 0
        let duration = 0
        machine.forEach(async (mac)=>{
            const isExpired = dateUtils.convertByDay(mac.end, currentTime)>0?true:false
            if(isExpired){
                duration = dateUtils.convertByDay(mac.start,mac.end)
            }else{
                duration = dateUtils.convertByDay(mac.start, currentTime)
            }
            
            const profitInCoin = duration * mac.rate 
            total = total + profitInCoin
        })
        
        return total
    }
}