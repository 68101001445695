export default {
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待定"])},
  "refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回收"])},
  "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买合约"])},
  "dailyTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日任务"])},
  "dailyCheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日签到"])},
  "checkInReward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["签到奖励"])},
  "inviteReward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请奖励"])},
  "inviteMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请你的好友注册即可获得奖励！"])},
  "usdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USDT"])},
  "doge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOGE"])},
  "btc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTC"])},
  "eth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETG"])},
  "zec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZEC"])},
  "documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档"])},
  "usdtFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tether"])},
  "dogeFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dogecoin"])},
  "btcFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin"])},
  "ethFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethereum"])},
  "zecFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zcash"])},
  "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["领取"])},
  "day": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["天"]), _normalize(["天"])])},
  "miner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["矿机"])},
  "starter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新手"])},
  "depositErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot deposit if you have pending deposits. Kindly contact our customer service for more info."])},
  "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数额"])},
  "minimumValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["数额需 ", _interpolate(_named("value")), " 以上"])},
  "insufficientBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["余额不足"])},
  "depositNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我已转入"])},
  "merchantBankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant's Bank Name"])},
  "merchantAccName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant's Acc Name"])},
  "merchantAccNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant's Acc Number"])},
  "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transfer"])},
  "minimumDeposit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimum Deposit Value is ", _interpolate(_named("value"))])},
  "rm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RM"])},
  "me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me"])},
  "verifyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证你的邮箱"])},
  "sendVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送验证链接"])},
  "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我已验证"])},
  "verificationSent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["验证链接已发送至 ", _interpolate(_named("email")), " 。 请检查你的Spam Folder。"])},
  "emailSendErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Send is unsuccessful. Please try again."])},
  "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
  "pageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Not Found"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至"])},
  "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兑换"])},
  "availableBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用余额"])},
  "totalExchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总兑换"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确定"])},
  "mining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["矿机收入"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "dailyReward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Reward"])},
  "invitationReward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation Reward"])},
  "btcConvertToUsdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTC 兑换至 USDT"])},
  "ethConvertToUsdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETH 兑换至 USDT"])},
  "dogeConvertToUsdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOGE 兑换至 USDT"])},
  "zecConvertToUsdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZEC 兑换至 USDT"])},
  "silver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silver"])},
  "gold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gold"])},
  "platinum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platinum"])},
  "diamond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diamond"])},
  "commission10%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10% income of direct subordinates mining profit."])},
  "commission1%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1% income of member mining profit."])},
  "commission1.5%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.5% income of member mining profit."])},
  "commission2%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2% income of member mining profit."])},
  "activeEconomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activating any economy miner"])},
  "activeStandard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activating any standard miner"])},
  "activeAdvanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activating any advanced miner"])},
  "btc/usdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTC/USDT"])},
  "eth/usdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETH/USDT"])},
  "doge/usdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOGE/USDT"])},
  "zec/usdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZEC/USDT"])},
  "latestPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新行情"])},
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合约期限"])},
  "estimatedMonthlyRevenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预估月收益"])},
  "minimumInvestment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低投入"])},
  "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已卖出"])},
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联络我们"])},
  "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["份"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载"])},
  "totalMiningProfit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总挖矿收入 (USD)"])},
  "activiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["激活中"])},
  "doNotOwnedMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你没有激活中的矿机"])},
  "investingAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投入数额"])},
  "contractPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合约期限"])},
  "currentMiningReward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前已挖"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["买入时间"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结束时间"])},
  "noCompletedMining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你没有已完成的矿机"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
  "totalReward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总收益"])},
  "addMiner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加矿机"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["币种"])},
  "contractDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合约期限"])},
  "estimatedDailyReward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预估日收益"])},
  "estimatedTotalReward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预估总收益"])},
  "refering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refer Friend. Earn Crypto Together"])},
  "totalTeamMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["团队总人数"])},
  "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会员"])},
  "totalCommission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总佣金 (USD)"])},
  "currentLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前等级"])},
  "yourReferalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的推广ID"])},
  "yourReferalLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的推广链接"])},
  "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
  "hasAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已注册？"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置密码"])},
  "emailReseted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email was sent successfully to reset your password."])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "proceedToSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前往登录"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
  "telegramGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社区"])},
  "announcement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement"])},
  "customerService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客服"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘记密码？"])},
  "referralTree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看团队树"])},
  "refId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UID"])},
  "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["矿收益"])},
  "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["佣金"])},
  "equity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总资产 (USD)"])},
  "myr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MYR"])},
  "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提款"])},
  "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兑换"])},
  "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通道"])},
  "withdrawAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提款数额"])},
  "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提款费用"])},
  "net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你将会收到"])},
  "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Name"])},
  "bankAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Account"])},
  "trc20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRC20"])},
  "futureHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The future is mine"])},
  "futureHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以全球最领先的矿机资源 为更多人提供优质的挖矿服务"])},
  "futureButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始挖矿"])},
  "futureHeader3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered and supervised by"])},
  "why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为什么选择我们？"])},
  "hashpower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球领先的算力服务提供商"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NX Miner是一家快速发展的数字资产挖矿服务商，也是全球领先的云挖矿服务商。NX Miner由全球领先的加密货币挖矿硬件制造商比特大陆投资，也是目前唯一的云挖矿战略合作伙伴。NX Miner致力于打造安全，合规，透明的区块链基础设施，为全球客户群提供各种稳定，智能的算力服务解决方案。"])},
  "easyHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["易"])},
  "easyDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一键下单 坐享收益"])},
  "oneStopHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全"])},
  "oneStopDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多币种算力及托管一体化服务"])},
  "costHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["省"])},
  "costDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优质的供应链整合能力，降低用户成本"])},
  "reliableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["稳"])},
  "reliableDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球合规矿场 7*24智能运维"])},
  "footer1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球领先的算力服务提供商"])},
  "telegram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram"])},
  "whatsApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WhatsApp"])},
  "dailyEarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预估日收益"])},
  "commissionUsdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USD"])},
  "minerUsdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USD"])},
  "homeCalculateEarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收益计算器"])},
  "Select the desired power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择币种"])},
  "investingInUSD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投入金额"])},
  "Power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["算力"])},
  "monthlyEarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预估月收益"])},
  "purchaseNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注意"])},
  "purchaseNotice1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合约结束后，矿机将会被自动回收。"])},
  "purchaseNotice2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平台将以100%的价格回收矿机。"])},
  "depositNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注意"])},
  "depositNotice1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请勿向当前地址进行任何非USDT的充值，否则资产将不会到账且无法找回。"])},
  "depositNotice2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值时，请务必确保提供正确的NX Miner充值地址，否则资产将不会到账且无法找回。"])},
  "purchaseHashPower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["算力"])}
}