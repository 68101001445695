<template>
<div class="mx-6">
    <div style="background: #19181D;border-radius: 8px;border-color:#19181d" class="border p-3 text-white">
        <div class="mb-2 font-semibold">{{$t('silver')}}</div>
        <div class="text-xs">{{$t('commission10%')}}</div>
    </div>
    <div style="background: #19181D;border-radius: 8px;border-color:#19181d" class="border p-3 text-white mt-6">
        <div class="mb-2 font-semibold">{{$t('gold')}}</div>
        <div class="text-xs">{{$t('commission10%')}}</div>
        <div class="text-xs mt-1.5">{{$t('commission1%')}}</div>
        <div style="color:#a9aaac" class="text-xs mt-4 mb-2">{{$t('activeEconomy')}}</div>
    </div>
     <div style="background: #19181D;border-radius: 8px;border-color:#19181d" class="border p-3 text-white mt-6">
        <div class="mb-2 font-semibold">{{$t('platinum')}}</div>
        <div class="text-xs">{{$t('commission10%')}}</div>
        <div class="text-xs mt-1.5">{{$t('commission1.5%')}}</div>
        <div style="color:#a9aaac" class="text-xs mt-4 mb-2">{{$t('activeStandard')}}</div>
    </div>
     <div style="background: #19181D;border-radius: 8px;border-color:#19181d" class="border p-3 text-white mt-6">
        <div class="mb-2 font-semibold">{{$t('diamond')}}</div>
        <div class="text-xs">{{$t('commission10%')}}</div>
        <div class="text-xs mt-1.5">{{$t('commission2%')}}</div>
        <div style="color:#a9aaac" class="text-xs mt-4 mb-2">{{$t('activeAdvanced')}}</div>
    </div>
</div>
</template>
