<template>
<div class="text-white text-center">{{$t('pageNotFound')}}</div>
</template>

<script>
export default {

}
</script>

<style>

</style>