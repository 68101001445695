import db from "@/firestore"
import { doc, updateDoc } from "firebase/firestore"
import { dateUtils } from "./dateUtils"
import { firestoreUtils } from "./firestoreUtils"

export class convertUtils{
    static getRoundedDecimal(value: number,decimal :number){
        return Math.round(value*Math.pow(10,decimal))/Math.pow(10,decimal)
    }

    static convertToUsdt(toBeConvert: number, usdtRate: number):number{
        return toBeConvert * usdtRate
    }

    static convertUsdtToCoin(toBeConvert: number, coinPrice:number):number{
        return toBeConvert/coinPrice
    }

    static async convertBtcToUsdt(referralCode :string, usdt: number, coin: number):Promise<void>{
        const data = await firestoreUtils.fetchKeyData("users", referralCode)
        const currentTimestamp = await dateUtils.getCurrentTimestamp()
        if(data != null) { 
            await updateDoc(doc(db, "users", referralCode), {
                balance: {
                    usdt:data.balance.usdt + usdt,
                    btc:data.balance.btc - coin,
                    eth:data.balance.eth,
                    doge:data.balance.doge,
                    zcash:data.balance.zcash
                }
            })
            await updateDoc(doc(db, "users", referralCode), {
                txn: data.txn.concat({
                    usdt: usdt,
                    btc: coin,
                    timestamp: currentTimestamp,
                    type: "convert"
                })
            })
        }
    }
    static async convertEthToUsdt(referralCode :string, usdt: number, coin: number):Promise<void>{
        const data = await firestoreUtils.fetchKeyData("users", referralCode)
        const currentTimestamp = await dateUtils.getCurrentTimestamp()
        if(data != null) {
            await updateDoc(doc(db, "users", referralCode), {
                balance: {
                    usdt:data.balance.usdt + usdt,
                    btc:data.balance.btc,
                    eth:data.balance.eth - coin,
                    doge:data.balance.doge,
                    zcash:data.balance.zcash
                }
            })
            await updateDoc(doc(db, "users", referralCode), {
                txn: data.txn.concat({
                    usdt: usdt,
                    eth: coin,
                    timestamp: currentTimestamp,
                    type: "convert"
                })
            })
        }
    }
    static async convertDogeToUsdt(referralCode :string, usdt: number, coin: number):Promise<void>{
        const data = await firestoreUtils.fetchKeyData("users", referralCode)
        const currentTimestamp = await dateUtils.getCurrentTimestamp()
        if(data != null) {
            await updateDoc(doc(db, "users", referralCode), {
                balance: {
                    usdt:data.balance.usdt + usdt,
                    btc:data.balance.btc,
                    eth:data.balance.eth ,
                    doge:data.balance.doge - coin,
                    zcash:data.balance.zcash
                }
            })
            await updateDoc(doc(db, "users", referralCode), {
                txn: data.txn.concat({
                    usdt: usdt,
                    doge: coin,
                    timestamp: currentTimestamp,
                    type: "convert"
                })
            })
        }
    }
    static async convertZecToUsdt(referralCode :string, usdt: number, coin: number):Promise<void>{
        const data = await firestoreUtils.fetchKeyData("users", referralCode)
        const currentTimestamp = await dateUtils.getCurrentTimestamp()
        if(data != null) {
            await updateDoc(doc(db, "users", referralCode), {
                balance: {
                    usdt:data.balance.usdt + usdt,
                    btc:data.balance.btc,
                    eth:data.balance.eth ,
                    doge:data.balance.doge,
                    zcash:data.balance.zcash  - coin,
                }
            })
            await updateDoc(doc(db, "users", referralCode), {
                txn: data.txn.concat({
                    usdt: usdt,
                    zec: coin,
                    timestamp: currentTimestamp,
                    type: "convert"
                })
            })
        }
    }
}