import { dateUtils } from "./dateUtils"
import { firestoreUtils } from "./firestoreUtils"
import { priceUtils } from "./priceUtils"
interface referralObject{
    label: string,
    machine:machineObject[] | undefined ,
    balance:balanceObject | undefined ,
    txn:txnObject[] | undefined ,
    children:this[]  | undefined 
}

export interface machineObject{
    start:number,
    end:number,
    model:number,
    amount:number,
    rate:number,
    hasClaim :boolean,
    type ?: string
}

export interface balanceObject{
    usdt:number,
    btc:number,
    eth:number,
    doge:number,
    zcash:number,
}

export interface txnObject{
    amount:number,
    timestamp:number,
    type: string,
    settled : boolean
}

interface treeObject{
    label:string,
    percentage?:number,
    totalEarnings?: number,
    children: this[] 
}

export interface historyObject{
    timestamp: number,
    rate: number,
    model:number,
    type: string
}

export class referralUtils{

    static async getTreeObject(referralObject:referralObject,level:number, btc: number, eth: number, doge: number, zec:number, currentTime :number):Promise<treeObject>{
        const treeObject:treeObject = {
            label:referralObject.label,
            children: []
        }
        if(referralObject.children!=undefined){
            for(const child of referralObject.children){
                if(child.machine!=undefined && child.children!=undefined){
                    treeObject.children.push({
                        label:child.label,
                        percentage:0.1,
                        totalEarnings: await this.calculateProfit(child.machine, btc, eth, doge, zec, currentTime),
                        children: await this.getChildTreeObject(child.children,level, btc, eth, doge, zec, currentTime)
                    })
                }
                
            }
        }
        return treeObject
    }
    static async getChildTreeObject(referralObject:referralObject[],level:number, btc: number, eth: number, doge: number, zec:number, currentTime :number):Promise<treeObject[]>{
        const childArray :treeObject[] = []
        for(const child of referralObject){
            if(child.machine!=undefined && child.children!=undefined){
                childArray.push({
                    label:child.label,
                    percentage:this.getRateByLevel(level),
                    totalEarnings: await this.calculateProfit(child.machine, btc, eth, doge, zec, currentTime),
                    children: await this.getChildTreeObject(child.children,level, btc, eth, doge, zec, currentTime )
                })
            }
        }
        return childArray
    }
    static async getreferralObject(referralCode :string) :Promise<referralObject> {
        const data = await this.getNextreferralObject(referralCode)
        return {
            label:referralCode,
            machine: data?.machine,
            balance: data?.balance,
            txn: data?.txn,
            children:await this.getChildObject(data?.children)
        }
    }

    static async getNextreferralObject(referralCode: string) :Promise<{label:string,machine:machineObject[],balance:balanceObject,txn:txnObject[],children:string[]} | null>{
        const data =  await firestoreUtils.fetchKeyData('users',referralCode)
        if(data!=null){
            const machine :machineObject[] = data.machine??[]
            const balance :balanceObject = data.balance??null
            const txn :txnObject[] = data.txn??[]
            const children :string[] = data.children??[]
            return {
                label: referralCode,
                machine: machine,
                balance: balance,
                txn: txn,
                children: children
            }
        }else{
            return null
        }
    }

    static async getChildObject(childrenArray :string[] | undefined ) :Promise<referralObject[]>{
        const childArray :referralObject[] = []
        if(childrenArray!=undefined ){
            for (const referralCode of childrenArray){ 
                const data = await this.getNextreferralObject(referralCode)
                childArray.push({
                    label:referralCode,
                    machine: data?.machine,
                    balance: data?.balance,
                    txn: data?.txn,
                    children:await this.getChildObject(data?.children)
                })
            }
        }
        return childArray
    }

    static getNumberOfChildreferrals (referral:referralObject []) :number{
        let count = 0
        referral.forEach(referral=>{
            if( referral.children!=undefined){
                count ++
                count = count + this.getNumberOfChildreferrals (referral.children)
            }
        })
        return count
    } 

    static getNumberOfreferrals(referral:referralObject):number{
        if(referral.children!=undefined){
            return this.getNumberOfChildreferrals(referral.children)
        }
        return 0
    }

    static async calculateCommissionSilver(referral:referralObject[], btc: number, eth: number, doge: number, zec:number,currentTime :number):Promise<{total :number,secondArray: referralObject[]}>{
        let total = 0
        const nextreferral:referralObject[] = []
        for (const ref of referral){
            if(ref.machine != undefined  && ref.children != undefined ){
                total = total + await this.calculateProfit(ref.machine, btc, eth, doge, zec, currentTime)
                ref.children.forEach(reff=>{ //second array
                    nextreferral.push(reff)
                })
            }
        }
        return {total:total * 0.1,secondArray:nextreferral}
    }


    static async calculateNextCommision(referral:referralObject[],percentage: number, btc: number, eth: number, doge: number, zec:number, currentTime :number){
        const basicObject = await this.calculateCommissionSilver(referral, btc, eth, doge, zec, currentTime)
        const basicTotal = basicObject.total
        const total = basicTotal + await this.getChildProfit(basicObject.secondArray,percentage, btc, eth, doge, zec, currentTime)
        return total
    }

    static async getChildProfit(referral:referralObject[],percentage:number, btc: number, eth: number, doge: number, zec:number, currentTime :number){
        let profit = 0
        for (const ref of referral){
            if( ref.children!=undefined  && ref.machine != undefined ){
                const x = await this.calculateProfit(ref.machine, btc, eth, doge, zec, currentTime) * percentage
                profit = profit +x //self and current
                if(ref.children != []){
                    const y = await this.getChildProfit(ref.children,percentage, btc, eth, doge, zec, currentTime)
                    profit = profit + y
                }
            }
        }
        return profit
    }
    
    
    static async calculateProfit(machine:machineObject[], btc: number, eth: number, doge: number, zec:number, currentTime :number):Promise<number>{
        let total = 0
       /*  const currentTime = await dateUtils.getCurrentTimestamp() */
        let duration = 0
        machine.forEach(async (mac)=>{
            const isExpired = dateUtils.convertByDay(mac.end, currentTime)>0?true:false
            if(isExpired){
                duration = dateUtils.convertByDay(mac.start,mac.end)
            }else{
                duration = dateUtils.convertByDay(mac.start, currentTime)
            }
            const coinPrice = priceUtils.getCoinPriceByModel(mac.model, btc, eth, doge, zec)
            const profitInUsdt = duration * mac.rate * coinPrice
            total = total + profitInUsdt
        })
        
        return total
    }
    
    

    static getLevel(machine:machineObject[]):number{
        let level = 0
        machine.forEach(mac=>{
            let x = mac.model
            if(x >6){
                x = x%6>=1?x%6:6
            }
            if(x > level){
                level = x
            }
        })
        return level
    }

    static getRateByLevel(level: number):number{
        if(level==0){
            return 0
        }
        if(level == 1){
            return 0
        }else if(level == 2){
            return 0.01
        }else if(level == 3){
            return 0.015
        }else {
            return 0.02
        }
    }

    static getLevelNameByLevel(level:number):string{
        switch(level){
            case 0:
                return 'Silver'
            case 1:
                return 'Silver'
            case 2:
                return 'Gold'
            case 3:
                return 'Platinum'
            case 4:
                return 'Diamond'
            case 5:
                return 'Diamond'
            case 6:
                return 'Diamond'
            default:
                return ''
        }
    }

    static async getCommission(referralCode :string,btcPrice :number, ethPrice :number, dogePrice :number, zecPrice :number, currentTime :number):Promise<number>{
        const referralObject = await this.getreferralObject(referralCode)
        let level = 0
        let rate = 0
        let totalCommission = 0
        if(referralObject.machine!=undefined){
            level= this.getLevel(referralObject.machine)
            rate= this.getRateByLevel(level)
        }
        if(level==0 || level ==1){
            if(referralObject.children!=undefined){
                const promise = await this.calculateCommissionSilver(referralObject.children, btcPrice, ethPrice, dogePrice, zecPrice, currentTime)
                totalCommission = Math.round(promise.total*Math.pow(10,6))/Math.pow(10,6)
            }
        }else{
            if(referralObject.children!=undefined ){
                totalCommission = Math.round(await this.calculateNextCommision(referralObject.children,rate, btcPrice, ethPrice, dogePrice, zecPrice, currentTime)*Math.pow(10,6))/Math.pow(10,6)
            }
        }
        return totalCommission

    }

    static async formatMachineToHistory(machine :machineObject[]) :Promise<historyObject[]>{
        const currentTimestamp = await dateUtils.getCurrentTimestamp()
        let numberOfDays = 0
        const formattedMachine = machine.map(mac=>{
            if(currentTimestamp - mac.end >0){ //expired
                numberOfDays = dateUtils.convertByDay(mac.start,mac.end)

            }else{ //not expired
                numberOfDays = dateUtils.convertByDay(mac.start,currentTimestamp)
            }
            return{
                start: mac.start,
                days: numberOfDays,
                rate: mac.rate,
                model: mac.model
            }
        })
        const formattedTxn :historyObject[]= []
        formattedMachine.forEach(mac=>{
            for(let i=1 ;i<=mac.days ;i++){
                if(i==1){
                    formattedTxn.push({
                        timestamp: mac.start,
                        rate:mac.rate,
                        model:mac.model,
                        type: 'mining'
                    })
                }else{
                    formattedTxn.push({
                        timestamp: mac.start +(86400000*i-1),
                        rate:mac.rate,
                        model:mac.model,
                        type:'mining'
                    })
                }
            }
        })
        return formattedTxn
    }
   
    //get commission for each coin 
    // use this in wallet view to count each coin
    static async getCommissionForEachCoin(referralCode :string,currentTime :number):Promise<{btc: number, eth: number, doge: number, zec: number}>{
        const referralObject = await this.getreferralObject(referralCode)
        let level = 0
        let rate = 0
        let totalBtc = 0
        let totalEth = 0
        let totalDoge = 0
        let totalZec = 0
        if(referralObject.machine!=undefined){
            level= this.getLevel(referralObject.machine)
            rate= this.getRateByLevel(level)
        }
        if(level==0 || level ==1){
            if(referralObject.children!=undefined){
                const data = this.calculateCommissionSilverForEachCoin(referralObject.children, currentTime)
                // totalCommission = 
                
                totalBtc = Math.round(data.btc*Math.pow(10,6))/Math.pow(10,6)
                totalEth = Math.round(data.eth*Math.pow(10,6))/Math.pow(10,6)
                totalDoge = Math.round(data.doge*Math.pow(10,6))/Math.pow(10,6)
                totalZec = Math.round(data.zec*Math.pow(10,6))/Math.pow(10,6)
            }
        }else{
            if(referralObject.children!=undefined ){
                const data =this.calculateNextCommisionForEachCoin(referralObject.children,rate, currentTime)
                totalBtc = Math.round(data.btc*Math.pow(10,6))/Math.pow(10,6)
                totalEth = Math.round(data.eth*Math.pow(10,6))/Math.pow(10,6)
                totalDoge = Math.round(data.doge*Math.pow(10,6))/Math.pow(10,6)
                totalZec = Math.round(data.zec*Math.pow(10,6))/Math.pow(10,6)
            }
        }
        
        return {
            btc: totalBtc,
            eth: totalEth,
            doge: totalDoge,
            zec: totalZec
        }

    }

    // calculate commision for each coin (direct commission)
    static  calculateCommissionSilverForEachCoin(referral:referralObject[], currentTime :number):{btc: number, eth: number, doge: number, zec: number, secondArray: referralObject[]}{
        let totalBtc = 0
        let totalEth = 0
        let totalDoge = 0
        let totalZec = 0
        const nextreferral:referralObject[] = []
        for (const ref of referral){
            
            if(ref.machine != undefined  && ref.children != undefined ){
                const data = this.calculateProfitForEachCoin(ref.machine, currentTime)
                totalBtc = totalBtc + data.btc
                totalEth = totalEth + data.eth
                totalDoge = totalDoge + data.doge
                totalZec = totalZec + data.zec
                ref.children.forEach(reff=>{ //second array
                    nextreferral.push(reff)
                })
            }
        }
       
        return {
            btc: totalBtc *0.1,
            eth: totalEth *0.1,
            doge: totalDoge *0.1,
            zec: totalZec *0.1,
            secondArray:nextreferral
        }
    }

    //mod for each coin
    static getChildProfitForEachCoin(referral:referralObject[],percentage:number,currentTime :number):{btc: number, eth: number, doge: number, zec: number}{
        let profitBtc = 0
        let profitEth = 0
        let profitDoge = 0
        let profitZec = 0
        for (const ref of referral){
            if( ref.children!=undefined  && ref.machine != undefined ){
                const x = this.calculateProfitForEachCoin(ref.machine, currentTime)
                profitBtc = profitBtc + (x.btc * percentage) //self and current 0
                profitEth = profitEth + (x.eth * percentage) //self and current
                profitDoge = profitDoge + (x.doge * percentage) //self and current
                profitZec = profitZec + (x.zec * percentage) //self and current
                
                if(ref.children.length>0){
                    const y = this.getChildProfitForEachCoin(ref.children,percentage, currentTime)
                    profitBtc = profitBtc + (y.btc) 
                    profitEth = profitEth + (y.eth) 
                    profitDoge = profitDoge + (y.doge) 
                    profitZec = profitZec + (y.zec) 
                }
            }
        }
        

        return {
            btc: profitBtc,
            eth: profitEth,
            doge: profitDoge,
            zec: profitZec,
        }
    }

    //calculate next commission for each coin
    static calculateNextCommisionForEachCoin(referral:referralObject[],percentage: number, currentTime :number):{btc: number, eth: number, doge: number, zec: number}{
        const basicObject = this.calculateCommissionSilverForEachCoin(referral, currentTime)
        const basicBtc = basicObject.btc
        const basicEth = basicObject.eth
        const basicDoge = basicObject.doge
        const basicZec = basicObject.zec
        const data = this.getChildProfitForEachCoin(basicObject.secondArray,percentage, currentTime)
        const totalBtc = basicBtc + data.btc
        const totalEth = basicEth + data.eth
        const totalDoge = basicDoge + data.doge
        const totalZec = basicZec + data.zec
        
        return {
            btc: totalBtc,
            eth: totalEth,
            doge: totalDoge,
            zec: totalZec,
        }
    }

    // calculate profit for each coin and return each coin
    static  calculateProfitForEachCoin(machine:machineObject[], currentTime :number):{btc: number, eth: number, doge: number, zec: number}{
        let totalBtc = 0
        let totalEth = 0
        let totalDoge = 0
        let totalZec = 0
        let duration = 0
        machine.forEach( (mac)=>{
            const isExpired = dateUtils.convertByDay(mac.end, currentTime)>0?true:false
            if(isExpired){
                duration = dateUtils.convertByDay(mac.start,mac.end)
            }else{
                duration = dateUtils.convertByDay(mac.start, currentTime)
            }
            const profit = duration * mac.rate

            if(mac.model <=6){
                totalBtc = totalBtc + profit
            }
            else if(mac.model <= 12){
                totalEth = totalEth + profit
            }
            else if(mac.model <= 18){
                totalDoge = totalDoge + profit
            }
            else{
                totalZec = totalZec + profit
            }
        })
                
        
        
        return {
            btc: totalBtc,
            eth: totalEth,
            doge: totalDoge,
            zec: totalZec
        }
    }

    
}

    
