<template>
   
      <div style="background:#1d1c22 "  class="items-center  px-2 py-2  rounded-md flex justify-between">
      <AutoNumericVue 
        :value="modelValue"
        :disabled ='disabled'
        class="supply_input text-white px-3"
        style="background:#1d1c22 "  
        :options="{
          showWarnings : false,
          digitGroupSeparator: ',',
          decimalCharacter: '.',
          currencySymbol: '',
          allowDecimalPadding: false,
          decimalPlaces: decimal,
          roundingMethod: 'U',
          overrideMinMaxLimits: 'invalid'
        }"
        @input="$emit('update:modelValue',parseFloat($event.target.value.replace(/,/g, '')).toString() )"
      >
      </AutoNumericVue>
      <div style="color:#f7a600" v-if="!noMax" class="text-center text-xs mr-6 cursor-pointer" @click="$emit('update:modelValue',maxAmount)">Max</div>
      </div>
</template>

<script>
import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';
export default{
  name:'NumberInput',
  props: {
    placeholder: String,
    errorMessage: String,
    showError: Boolean,
    modelValue: String,
    disabled: Boolean,
    decimal: Number,
    balance: Number,
    minimumValue: String,
    maxAmount: String,
    noMax: Boolean
  },
  components:{
    AutoNumericVue
  },
  emits:[
    'update:modelValue', 'show-error'
  ],
}
</script>
<style  scoped>

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.supply_input{
  @apply w-full flex border-0 outline-none border-white drop-shadow-none filter focus:outline-none text-sm text-white  disabled:opacity-50;
}
</style>