export default {
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
  "refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])},
  "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase"])},
  "dailyTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Task"])},
  "dailyCheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Check In"])},
  "checkInReward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check In Reward"])},
  "inviteReward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviting Rewards"])},
  "inviteMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviting your friends and get rewarded!"])},
  "usdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USDT"])},
  "doge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOGE"])},
  "btc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTC"])},
  "eth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETG"])},
  "zec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZEC"])},
  "documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
  "usdtFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tether"])},
  "dogeFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dogecoin"])},
  "btcFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin"])},
  "ethFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethereum"])},
  "zecFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zcash"])},
  "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["claim"])},
  "day": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Day"]), _normalize(["Days"])])},
  "miner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["miner"])},
  "starter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter"])},
  "depositErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot deposit if you have pending deposits. Kindly cancel your last pending deposit in history to proceed depositing."])},
  "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amount"])},
  "minimumValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimum value is ", _interpolate(_named("value"))])},
  "insufficientBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient Balance"])},
  "depositNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment done, notify admin"])},
  "merchantBankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant's Bank Name"])},
  "merchantAccName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant's Acc Name"])},
  "merchantAccNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant's Acc Number"])},
  "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transfer"])},
  "minimumDeposit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimum Deposit Value is ", _interpolate(_named("value"))])},
  "rm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RM"])},
  "me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me"])},
  "verifyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify Your Email Address"])},
  "sendVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Email Verification"])},
  "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am verified"])},
  "verificationSent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["An email has been sent to ", _interpolate(_named("email")), " with a link to verify your account. You may find it in your spam folder."])},
  "emailSendErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Send is unsuccessful. Please try again."])},
  "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])},
  "pageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Not Found"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
  "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exchange"])},
  "availableBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Balance"])},
  "totalExchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Exchange"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm"])},
  "mining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mining"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "dailyReward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Reward"])},
  "invitationReward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation Reward"])},
  "btcConvertToUsdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTC Convert To USDT"])},
  "ethConvertToUsdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETH Convert To USDT"])},
  "dogeConvertToUsdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOGE Convert To USDT"])},
  "zecConvertToUsdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZEC Convert To USDT"])},
  "silver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silver"])},
  "gold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gold"])},
  "platinum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platinum"])},
  "diamond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diamond"])},
  "commission10%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10% income of direct subordinates mining profit."])},
  "commission1%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1% income of member mining profit."])},
  "commission1.5%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.5% income of member mining profit."])},
  "commission2%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2% income of member mining profit."])},
  "activeEconomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activating any economy miner"])},
  "activeStandard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activating any standard miner"])},
  "activeAdvanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activating any advanced miner"])},
  "btc/usdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTC/USDT"])},
  "eth/usdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETH/USDT"])},
  "doge/usdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOGE/USDT"])},
  "zec/usdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZEC/USDT"])},
  "latestPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Price"])},
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
  "estimatedMonthlyRevenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated Monthly Revenue"])},
  "minimumInvestment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Investment"])},
  "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold"])},
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "totalMiningProfit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Mining Profit (USD)"])},
  "activiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activing"])},
  "doNotOwnedMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not own any mining machines yet."])},
  "investingAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investing Amount"])},
  "contractPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Period"])},
  "currentMiningReward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Mining Reward"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
  "noCompletedMining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not completed any minings."])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
  "totalReward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Reward"])},
  "addMiner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Miner"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
  "contractDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Duration"])},
  "estimatedDailyReward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated Daily Reward"])},
  "estimatedTotalReward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated Total Reward"])},
  "refering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refer Friend. Earn Crypto Together"])},
  "totalTeamMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Team Members"])},
  "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["members"])},
  "totalCommission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Commission (USD)"])},
  "currentLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Level"])},
  "yourReferalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your referral ID"])},
  "yourReferalLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your referral link"])},
  "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
  "hasAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
  "emailReseted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email was sent successfully to reset your password."])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "proceedToSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed To Sign In"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "telegramGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram Group"])},
  "announcement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement"])},
  "customerService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Service"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])},
  "referralTree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Referral Tree"])},
  "refId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UID"])},
  "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenue"])},
  "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
  "equity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equity Value (USD)"])},
  "myr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MYR"])},
  "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw"])},
  "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convert"])},
  "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
  "withdrawAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw Amount"])},
  "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
  "net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net amount to the account"])},
  "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Name"])},
  "bankAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Account"])},
  "trc20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRC20"])},
  "futureHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The future is mine"])},
  "futureHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We focus on providing superior mining services for everyone with world-leading mining resources."])},
  "futureButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Mining"])},
  "futureHeader3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered and supervised by"])},
  "why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why Should Us?"])},
  "hashpower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The world's top hashpower service provider"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NX Miner is a fast-growing digital asset mining service and a world-leading cloud-mining service provider. NX Miner has been invested by, and is the only cloud-mining strategic partner of Bitmain to date,the world's leading cryptocurrency mining hardware manufacturer."])},
  "easyHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy"])},
  "easyDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One click order Enjoy Rewards"])},
  "oneStopHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Stop Service"])},
  "oneStopDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud Mining and Hosting All in one"])},
  "costHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost-effective"])},
  "costDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High-performing supply chain with best offer"])},
  "reliableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reliable"])},
  "reliableDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global high quality mining forms, 24x7 intelligent OSM"])},
  "footer1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The world's top hashpower service provider"])},
  "telegram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram"])},
  "whatsApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WhatsApp"])},
  "dailyEarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Earning"])},
  "commissionUsdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USD"])},
  "minerUsdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USD"])},
  "homeCalculateEarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate Earning"])},
  "Select the desired power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the desired power"])},
  "investingInUSD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investing in $"])},
  "Power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash Power"])},
  "monthlyEarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Earning"])},
  "purchaseNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
  "purchaseNotice1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After contract period, mining machine will be buy back."])},
  "purchaseNotice2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal amount will be return to your account after contract period"])},
  "depositNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
  "depositNotice1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do note that only USDT is accepted via this address. Otherwise, your deposited funds will not be added to your balance, nor will it be refunded."])},
  "depositNotice2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For a successful deposit, kindly ensure that the wallet address is accurate."])},
  "purchaseHashPower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash Power"])}
}