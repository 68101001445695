import { createApp, ref } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import VueBlocksTree from 'vue3-blocks-tree';
import 'vue3-blocks-tree/dist/vue3-blocks-tree.css';
import { priceUtils } from './utils/priceUtils';
import i18n from './i18n'
const defaultoptions = {treeName:'blocks-tree'}

export const vue = createApp(App)
vue
.use(i18n)
.use(VueBlocksTree,defaultoptions)
.use(router)
.mount('#app')

export const btcPrice = ref(0)
export const ethPrice = ref(0)
export const dogePrice = ref(0)
export const zecPrice = ref(0)

const fetchAllCurrencyPrice = async()=>{ 
    btcPrice.value = await priceUtils.getCoinPrice('bitcoin')
    ethPrice.value = await priceUtils.getCoinPrice('ethereum')
    dogePrice.value = await priceUtils.getCoinPrice('dogecoin')
    zecPrice.value = await priceUtils.getCoinPrice('zcash')
}

fetchAllCurrencyPrice()

setInterval(async()=>{
    await fetchAllCurrencyPrice()
},60000)

const getItem = localStorage.getItem('language')
if(getItem==null){
    localStorage.setItem('language','en')
}









