import { arrayRemove, doc, DocumentData, getDoc, updateDoc } from 'firebase/firestore'
import db from '../firestore'
import { dateUtils } from './dateUtils'
import {  machineObject, txnObject } from './referralUtils'

interface depositCashflow{
    id: string,
    usdt:number,
    timestamp:number,
    settled:boolean
}

export class firestoreUtils{
    static async fetchKeyData (collectionName :string, key :string) :Promise<DocumentData | null>{
        const content = await getDoc(doc(db,collectionName,key))
        if(content.exists()){ 
            const data = content.data()
            return data
        }else{
            return null
        }
    }

    static async checkPendingDeposit(referralCode :string):Promise<boolean>{
        const data = await this.fetchKeyData("users", referralCode)
        /* const currentTimestamp = await dateUtils.getCurrentTimestamp() */
        if(data != null) {
            const txn :txnObject[]= data.txn
            const depositTxn = txn.filter(tx=>tx.type == 'deposit')
            if(depositTxn.every(tx=>tx.settled == true) || depositTxn.length == 0){
                return true
            }else{
                return false
            }
        }
        return false
    }

    static async updateDailyClaim(referralCode :string,amount :number,model :number,day:number,rate :number):Promise<void>{
        const data = await this.fetchKeyData("users", referralCode)
        const currentTimestamp = await dateUtils.getCurrentTimestamp()
        if(data != null) {
            await updateDoc(doc(db, "users", referralCode), {
                dailyTimestamp: currentTimestamp,
                txn: data.txn.concat({
                    amount: amount,
                    model:model,
                    timestamp: currentTimestamp,
                    type: "daily"
                }),
                machine: data.machine.concat({
                    start: currentTimestamp,
                    end: currentTimestamp+(day*86400000),
                    model: model,
                    amount: amount,
                    rate: rate,
                    hasClaim: false,
                    type: 'daily'
                }),
            })
        }
    }
    static async updateInviteCount(referralCode :string,amount :number,model :number,day:number,rate :number):Promise<void>{
        const data = await this.fetchKeyData("users", referralCode)
        const currentTimestamp = await dateUtils.getCurrentTimestamp()
        if(data != null) {
            await updateDoc(doc(db, "users", referralCode), {
                inviteCounter: data.inviteCounter+5,
                txn: data.txn.concat({
                    amount: amount,
                    model:model,
                    timestamp: currentTimestamp,
                    type: "invite"
                }),
                machine: data.machine.concat({
                    start: currentTimestamp,
                    end: currentTimestamp+(day*86400000),
                    model: model,
                    amount: amount,
                    rate: rate,
                    hasClaim: false,
                    type: 'invite'
                }),
            })
        }
    }

    static async updateTxn(referralCode :string, amount: number, day: number, model: number, rate: number):Promise<void>{
        const data = await this.fetchKeyData("users", referralCode)
        const currentTimestamp = await dateUtils.getCurrentTimestamp()
        if(data != null) {
            await updateDoc(doc(db, "users", referralCode), {
                txn: data.txn.concat({
                    amount: amount,
                    model:model,
                    timestamp: currentTimestamp,
                    type: "purchase"
                }),
                machine: data.machine.concat({
                    start: currentTimestamp,
                    end: currentTimestamp+(day*86400000),
                    model: model,
                    amount: amount,
                    rate: rate,
                    hasClaim: false
                }),
                balance: {
                    usdt:data.balance.usdt - amount,
                    btc:data.balance.btc,
                    eth:data.balance.eth,
                    doge:data.balance.doge,
                    zcash:data.balance.zcash
                }
            })
        }
        const configData = await this.fetchKeyData("records", 'config')
        const temp= configData?.sold
        temp[model-1] ++
        if(configData!=null){
            await updateDoc(doc(db, "records", 'config'), {
                sold: temp
            })
        }
    }

    static async updateStatus(referralCode :string, object: machineObject[]):Promise<void>{
        await updateDoc(doc(db, "users", referralCode), {
            machine: object
        })
    }
    
    static async updateTotalClaim(referralCode :string, totalClaim: number):Promise<void>{
        const data = await this.fetchKeyData("users", referralCode)
        if(data != null) {
            await updateDoc(doc(db, "users", referralCode), {
                balance: {
                    usdt:data.balance.usdt + totalClaim,
                    btc:data.balance.btc,
                    eth:data.balance.eth,
                    doge:data.balance.doge,
                    zcash:data.balance.zcash
                }
            })
        }
    }
    static async updateWithdrawBalance(referralCode :string, amount: number):Promise<void>{
        const data = await this.fetchKeyData("users", referralCode)
        if(data != null) {
            await updateDoc(doc(db, "users", referralCode), {
                balance: {
                    usdt:data.balance.usdt - amount,
                    btc:data.balance.btc,
                    eth:data.balance.eth,
                    doge:data.balance.doge,
                    zcash:data.balance.zcash
                }
            })
        }
    }
    static async updateRefundHistory(referralCode :string,amount: number, model: number):Promise<void>{
        const data = await this.fetchKeyData("users", referralCode)
        const currentTimestamp = await dateUtils.getCurrentTimestamp()
        if(data != null) {
            await updateDoc(doc(db, "users", referralCode), {
                txn: data.txn.concat({
                    amount: amount,
                    model: model,
                    timestamp: currentTimestamp,
                    type: "refund"
                })
            })
        }
    }
    
    static async removeDepositHistory(referralCode :string):Promise<void>{
        const data = await this.fetchKeyData("users", referralCode)
        if(data != null) {
            const txnData :txnObject[] = data.txn
            const dataToRemove = txnData.find(data=>data.type=='deposit' && data.settled == false)
            await updateDoc(doc(db, "users", referralCode), {
                txn: arrayRemove(dataToRemove)
            })
        }
        const cashflow = await this.fetchKeyData("records", "cashFlow")
        if(cashflow != null) {
            const txnData :depositCashflow[] = cashflow.deposit
            const dataToRemove = txnData.find(data=>data.settled == false && data.id == referralCode)
            await updateDoc(doc(db, "records", "cashFlow"), {
                deposit: arrayRemove(dataToRemove)
            })
        }
    }

    static async updateDepositHistory(referralCode :string,amount: number, address: string):Promise<void>{
        const data = await this.fetchKeyData("users", referralCode)
        const currentTimestamp = await dateUtils.getCurrentTimestamp()
        if(data != null) {
            await updateDoc(doc(db, "users", referralCode), {
                txn: data.txn.concat({
                    amount: amount,
                    timestamp: currentTimestamp,
                    type: "deposit",
                    settled: false
                })
            })
        }
        const cashflow = await this.fetchKeyData("records", "cashFlow")
        if(cashflow != null) {
            await updateDoc(doc(db, "records", "cashFlow"), {
                deposit: cashflow.deposit.concat({
                    id: referralCode,
                    address: address,
                    usdt: amount,
                    timestamp: currentTimestamp,
                    settled: false
                })
            })
        }
    }

    static async updateDepositHistoryFiat(referralCode :string,amount: number, myrRate: number):Promise<void>{
        const data = await this.fetchKeyData("users", referralCode)
        const currentTimestamp = await dateUtils.getCurrentTimestamp()
        if(data != null) {
            await updateDoc(doc(db, "users", referralCode), {
                txn: data.txn.concat({
                    amount: amount,
                    timestamp: currentTimestamp,
                    type: "deposit",
                    settled: false
                })
            })
        }
        const cashflow = await this.fetchKeyData("records", "cashFlow")
        if(cashflow != null) {
            await updateDoc(doc(db, "records", "cashFlow"), {
                deposit: cashflow.deposit.concat({
                    id: referralCode,
                    usdt: amount,
                    myr: amount*myrRate,
                    timestamp: currentTimestamp,
                    settled: false,
                })
            })
        }
    }

    static async updateWithdrawHistory(referralCode :string,amount: number, myrRate: number, fee:number, address:string):Promise<void>{
        const data = await this.fetchKeyData("users", referralCode)
        const currentTimestamp = await dateUtils.getCurrentTimestamp()
        if(data != null) {
            await updateDoc(doc(db, "users", referralCode), {
                txn: data.txn.concat({
                    amount: amount,
                    timestamp: currentTimestamp,
                    type: "withdraw",
                    settled: false
                })
            })
        }
        const cashflow = await this.fetchKeyData("records", "cashFlow")
        if(cashflow != null) {
            await updateDoc(doc(db, "records", "cashFlow"), {
                withdraw: cashflow.withdraw.concat({
                    id: referralCode,
                    usdt: amount*(1-fee),
                    myr: amount*(1-fee)*myrRate,
                    timestamp: currentTimestamp,
                    address: address,
                    settled: false
                })
            })
        }
    }

    static async updateWithdrawHistoryFiat(referralCode :string,amount: number, myrRate: number, fee:number, bank:string, acc:string):Promise<void>{
        const data = await this.fetchKeyData("users", referralCode)
        const currentTimestamp = await dateUtils.getCurrentTimestamp()
        if(data != null) {
            await updateDoc(doc(db, "users", referralCode), {
                txn: data.txn.concat({
                    amount: amount,
                    timestamp: currentTimestamp,
                    type: "withdraw"
                })
            })
        }
        const cashflow = await this.fetchKeyData("records", "cashFlow")
        if(cashflow != null) {
            await updateDoc(doc(db, "records", "cashFlow"), {
                withdraw: cashflow.withdraw.concat({
                    id: referralCode,
                    usdt: amount*(1-fee),
                    myr: amount*(1-fee)*myrRate,
                    timestamp: currentTimestamp,
                    settled: false,
                    bank: bank,
                    acc: acc
                })
            })
        }
    }
    
}