export class dateUtils{
    static convertByDay(startingTimestamp :number,endTimestamp :number):number{
        return Math.floor((endTimestamp - startingTimestamp) / 86400000)
    }

    static getApiTime() :Promise<string>{
        return fetch("https://worldtimeapi.org/api/timezone/Asia/Kuala_Lumpur")
        .then((res) => res.json())
        .then((data) => { return data.datetime });
    }

    static async getCurrentTimestamp(){
        let time :string
        let date :Date
        try {
            time = await this.getApiTime()
            date = new Date(time)
        } catch (error) {
            date = new Date()
        }
        
        return Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
            date.getUTCMilliseconds()
        )
    }
}