import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import MarketView from '../views/MarketView.vue'
import SignInView from '../views/SignInView.vue'
import RegisterView from '../views/RegisterView.vue'
import MinerView from '../views/MinerView.vue'
import ReferralView from '../views/ReferralView.vue'
import WalletView from '../views/WalletView.vue'
import PurchaseView from '../views/PurchaseView.vue'
import ResetPassView from '../views/ResetPassView.vue'
import SettingsView from '../views/SettingsView.vue'
import LevelView from '../views/LevelView.vue'
import DailyView from '../views/DailyView.vue'
import ExchangeView from '../views/ExchangeView.vue'
import DepositView from '../views/DepositView.vue'
import WithdrawView from '../views/WithdrawView.vue'
import HistoryView from '../views/HistoryView.vue'
import EmailVerifyView from '../views/EmailVerifyView.vue'
import ErrorView from '../views/ErrorView.vue'
import HomeView from '../views/HomeView.vue'
import {getAuth, onAuthStateChanged, User} from 'firebase/auth'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: HomeView,
  },
  {
    path: '/market',
    component: MarketView,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: '/email-verification',
    component: EmailVerifyView,
   
  },
  {
    path: '/deposit',
    component: DepositView,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: '/withdraw',
    component: WithdrawView,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: '/history',
    component: HistoryView,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: '/exchange',
    component: ExchangeView,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: '/daily',
    component: DailyView,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: '/level',
    component: LevelView,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: '/settings',
    component: SettingsView,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: '/purchase/:machineId',
    props:true,
    component: PurchaseView,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: "/sign-in",
    component: SignInView
  },
  {
    path: "/register/:refLink?",
    props:true,
    component: RegisterView
  },
  {
    path: "/reset-pass",
    component: ResetPassView
  },
  {
    path: "/referral",
    component: ReferralView,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: "/miner",
    component: MinerView,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: "/wallet",
    component: WalletView,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: "/notFound",
    component: ErrorView,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const getCurrentUser = () :Promise<User | null>=>{
  return new Promise((resolve,reject)=>{
    const removeListener = onAuthStateChanged(
      getAuth(),
      user=>{
        removeListener()
        resolve(user)
      },
      reject
    )
  })
}

router.beforeEach(async(to,from,next)=>{
  if (!to.matched.length) {
    next('/notFound');
  }
  if(to.matched.some((record)=>record.meta.requiresAuth)){
    const user = await getCurrentUser()
    if(user) {
      if(user.emailVerified){
        next()
      }else{
        next('/email-verification')
      }
    }else{
      next('/')
    }
  }else{
    next()
  }
})

export default router
