export class priceUtils{
    
    static getCoinPrice(coinId :string) :Promise<number>{
        return fetch(`https://api.coingecko.com/api/v3/coins/${coinId}`)
        .then((res) => res.json())
        .then((data) => { return data.market_data.current_price.usd });
    }

    static getCoinPriceByModel(model:number, btc:number, eth:number, doge:number, zcash:number){
        if (model <= 6 && model >= 0) {
             return btc
        }else if(model <= 12) {
            return eth
        }else if(model <= 18){
            return doge
        }else {
            return zcash
        }
    }
    static getCoinPriceByCoin(coin:string, btc:number, eth:number, doge:number, zec:number){
        if (coin == "btc"){
            return btc
        }
        else if (coin == "eth"){
            return eth
        }
        else if (coin == "doge"){
            return doge
        }
        else if(coin == "zec"){
            return zec
        }
    }

    static getBtcToMyrRate():Promise<number>{
        return fetch("https://api.coingecko.com/api/v3/exchange_rates")
        .then((res) => res.json())
        .then((data) => { return data.rates.myr.value });
    }

    static async getUsdToMyrRate(){
        const bitcoinPrice = await this.getCoinPrice('bitcoin')
        const btcToMyrRate = await this.getBtcToMyrRate()
        return Math.round((btcToMyrRate/bitcoinPrice)*Math.pow(10,2))/Math.pow(10,2)
    }

}